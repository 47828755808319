import { getUserAgentInfos } from './utils'

const userAgents = [
    {
        name: 'Chrome 78 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 6.3; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/78.0.3904.97 Safari/537.36',
        shouldSupport: false,
    },
    {
        name: 'Chrome 79 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.130 Safari/537.36',
        shouldSupport: false,
    },
    {
        name: 'Chrome 109 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.3945.130 Safari/537.36',
        shouldSupport: true,
    },
    {
        name: 'Chrome 126 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36',
        shouldSupport: true,
    },
    {
        name: 'Chrome 126 Android',
        UAstring:
            'Mozilla/5.0 (Linux; Android 10; K) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.6478.110 Mobile Safari/537.36',
        shouldSupport: false,
    },
    {
        name: 'Chrome Headless 108 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/108.0.5359.125 Safari/537.36',
        shouldSupport: false,
    },
    {
        name: 'Chrome Headless 109 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) HeadlessChrome/109.0.5414.119 Safari/537.36',
        shouldSupport: true,
    },
    {
        name: 'Firefox 79 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:798.0) Gecko/20100101 Firefox/79.0',
        shouldSupport: false,
    },
    {
        name: 'Firefox 80 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:80.0) Gecko/20100101 Firefox/80.0/3ksI6CtQ-58',
        shouldSupport: false,
    },
    {
        name: 'Firefox 115 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:115.0) Gecko/20100101 Firefox/115.0/3ksI6CtQ-58',
        shouldSupport: true,
    },
    {
        name: 'Firefox 125 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:125.0) Gecko/20100101 Firefox/125.0',
        shouldSupport: true,
    },
    {
        name: 'Edge 17 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/64.0.3282.140 Safari/537.36 Edge/17.17134',
        shouldSupport: false,
    },
    {
        name: 'Edge 79 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.29 Safari/537.36 Edg/79.0.309.18',
        shouldSupport: false,
    },
    {
        name: 'Edge 127 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/127.0.3945.29 Safari/537.36 Edg/127.0.309.18',
        shouldSupport: true,
    },
    {
        name: 'Edge 100 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.75 Safari/537.36 Edg/100.0.1185.36',
        shouldSupport: true,
    },
    {
        name: 'Safari 17.4.1 IOS',
        UAstring:
            'Mozilla/5.0 (iPhone; CPU iPhone OS 17_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/17.4.1 Mobile/15E148 Safari/604.1',
        shouldSupport: false,
    },
    {
        name: 'Opera 111 Windows',
        UAstring:
            'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/126.0.0.0 Safari/537.36 OPR/111.0.0.0',
        shouldSupport: false,
    },
    {
        name: 'Bad UA string',
        UAstring: 'This is a bad ua string',
        shouldSupport: false,
    },
]

describe('UnsupportedBrowserNotification', () => {
    test.each(userAgents)(
        `should support $name ? expecting $shouldSupport`,
        ({ UAstring, shouldSupport }) => {
            expect(getUserAgentInfos(UAstring).supported).toBe(shouldSupport)
        }
    )
})
